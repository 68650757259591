@import '../../scss/helpers/variables';
@import '../../scss/helpers/mixins';

.bg-primary {
    background-color: $primary-color;
    color: $white;
}

.text-primary {
    color: $primary-color;
}

.section-padding {
    padding-bottom: 50px;
    padding-top: 50px;

    @include breakpoint(mobile-max) {
        padding-bottom: 30px;
        padding-top: 30px;
    }
}

.bottom-margin {
    margin-bottom: 50px;

    @include breakpoint(mobile-max) {
        margin-bottom: 10px;
    }
}

.para-padding {
    padding-bottom: 30px;
    padding-top: 30px;

    @include breakpoint(mobile-max) {
        padding-bottom: 20px;
        padding-top: 20px;
    }
}

.bullet-list {
    >li {
        position: relative;
        padding-left: 15px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 7px;
            background-color: $text-color;
            height: 5px;
            width: 5px;
            border-radius: 100%;
        }
    }
}

.shadow-box {
    box-shadow: 0 1px 2px rgba(3, 54, 63, .4), 0 -1px 2px rgba(3, 54, 63, .04);
}