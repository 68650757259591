html {
	font-size: 16px;
	scroll-behavior: smooth;
}

body {
	color: $text-color;
	font-family: $primary-fonts;
	font-size: 1rem;
}

.container {
	max-width: $container-width;
}