//Helpers
@import "assets/scss/helpers/__helpers-dir";

//Base Styles
@import "assets/scss/base/__base-dir";

//Components
@import "assets/scss/components/__components-dir";

.about-image-section {
  position: relative;
  width: 100%;

  overflow: hidden;

  @include breakpoint(mobile-max) {
    z-index: -1;
    height: auto;
  }

  .background-img {
    position: relative;
    padding-top: 4vw;

    @include breakpoint(ipad-max) {
      padding-top: 6vw;
    }

    @include breakpoint(mobile-max) {
      padding-top: 12vw;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .overlay-effect {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4vw;
    background: #311d40;
    z-index: 2;

    @include breakpoint(ipad-max) {
      height: 6vw;
    }

    @include breakpoint(portrait-mobile-max) {
      height: 17vw !important;
    }

    @include breakpoint(mobile-max) {
      height: 12vw;
    }
  }
}

.navbar-toggler-icon {
  @include breakpoint(ipad-min) {
    width: 3vw !important;
    height: 3vw !important;
  }

}

// section contact start
.contact-detail-section {
  padding: 100px 0;
  height: 250px;
  width: 100%;
  background: linear-gradient(0deg, #000, rgba(0, 0, 0, 0) 100.04%);
  background: linear-gradient(to bottom,
      rgba(65, 0, 60) 0%,
      rgba(128, 0, 128, 0));

  @include breakpoint(mobile-max) {
    height: 220px;
    padding-top: 30px;
  }
}


// section contact end

//thank you navbar

.thank-detail-section {
  height: 73px;
  width: 100%;
  background: linear-gradient(0deg, #000, #0000 100.04%);
  background: linear-gradient(to bottom, #41003c, #8000803d);

  @include breakpoint(mobile-max) {
    padding-top: 30px;
  }
}

//thank you navbar end



// section offer
.offerdetail .owl-carousel .owl-nav {
  position: absolute;
  right: 20px;
  top: 42%;
  width: 100%;

  @media screen and (max-width:768px) {
    display: none;
  }

  @include breakpoint(mobile-max) {
    right: 5px;
    display: none;
  }

  .owl-prev {
    position: absolute;
    left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: url(assets/img/prev-arrow-curve.svg);
    //transform: rotate(180deg);
    background-repeat: no-repeat;
    background-color: $light-bg-color;
    background-position: center;
    background-size: 50%;
    box-shadow: #00000040 1px 1px 4px;

    @include breakpoint(mobile-max) {
      right: -8px;
      width: 32px;
      height: 32px;
    }
  }

  .owl-next {
    position: absolute;
    right: -52px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: url(assets/img/next-arrow-curve.svg);
    background-color: $light-bg-color;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    box-shadow: #00000040 1px 1px 4px;

    @include breakpoint(mobile-max) {
      right: -8px;
      width: 32px;
      height: 32px;
    }
  }
}

//media section arrows
.media-offerdetail .owl-carousel .owl-nav {
  position: absolute;
  right: 21px !important;
  top: 30px;
  width: 100%;

}

.celeb-arrow .owl-carousel .owl-nav {
  position: absolute;
  top: 30%;
  width: 100%;

  .owl-prev {
    position: absolute;
    left: 11px;
  }

  .owl-next {
    position: absolute;
    right: -54px;
  }
}

.celeb-video .owl-carousel .owl-nav {
  position: absolute;
  right: 21px !important;
  top: 42%;
  width: 100%;

  .owl-prev {
    position: absolute;
    left: 1px;
  }

  .owl-next {
    position: absolute;
    right: -53px;
  }
}

.view-all-btn {
  background-color: $secondary-color;
  color: #fff;
  border: 0;

  &:hover,
  &:focus,
  &.active {
    border: 0;
    background-color: #a57b3a !important;
    font-weight: 500;
  }
}

.toast-container {
  position: fixed;
  bottom: 0;
  z-index: 9999999;
  overflow: visible !important;
}

.toast-top-center {
  left: 50% !important;
  transform: translateX(-50%) !important;
  text-align: center !important;
}

html,
body {
  margin: 0;

}

//header pop-up locator
.cdk-global-overlay-wrapper {
  height: 100% !important;

}

.mat-mdc-dialog-surface {
  overflow-y: inherit !important;
}

.dialog-open .main-container,
.dialog-open body {
  filter: blur(5px);
  /* Adjust blur intensity */
  pointer-events: none;

}

html,

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.mob-banner {
  display: none;

  @include breakpoint(mobile-max) {
    display: block;
  }
}

.desk-banner {
  display: block;

  @include breakpoint(mobile-max) {
    display: none;
  }
}

img {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

img[src] {
  opacity: 1;
}
