.breadcrumb {
    font-size: torem(12px);
    color: $secondary-color;
    padding: 10px 0;

    a {
        color: $text-color;
    }

    span {
        cursor: not-allowed;
    }
    
    li {
        &:nth-child(1) {
            a {
                span {
                    cursor: pointer;
                }
            }
        }
    }
}