@import '../../scss/helpers/variables';
@import '../../scss/helpers/mixins';

body {
    overflow-x: hidden;
}

.text-bg-primary {
    background-color: $primary-color !important;
}

.shadow {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.white {
    color: $white;
}

.fnt-15 {
    font-size: 15px;
}

.heading-sub-title {
    word-wrap: break-word;
    /* Ensures long words break to fit in container */
    overflow-wrap: break-word;
    /* Another way to ensure breaking */
    white-space: normal;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 8px;
}