.form-control,
.form-check-input,
.form-select {
    &:focus {
        box-shadow: none;
    }
}


.form-check {
    padding-left: 35px;
    .form-check-input {
        &[type="checkbox"] {
            border-radius: 3px;
            margin-left: -35px;
            margin-top: 0;
        }
    }
}
