.nav-pills {
    .nav-item {
        margin-right: 12px;
        margin-bottom: 12px;
    }
    .nav-link {
        border: 1px solid $primary-color;
        color: $primary-color;
        padding: 12px 15px;
        &:hover,
        &:focus,
        &.active {
            background-color: $primary-color;
            color: $white;
        }
    }
}
.nav-pills-rounded {
    .nav-link {
        border-radius: 30px;
    }
}