// Fonts
$primary-fonts: "Poppins", Arial, sans-serif;

// Container width
$container-width: 1300px;

// Color
$primary-color: #ee018c;
$secondary-color: #bd8d43;
$background-color: #311d40;
$para-color: #666666;
$text-color: #111;
$text-semi-color: #212529;
$placeholder-color: #969494;
$link-color: #ed1c24;
$white: #fff;
$black: #000;
$black-light-color: #403d3d6b;
$card-background-color: #f6f3ed;
$border-color: #6666664d;
$light-bg-color: #f8f6f1;
$light-text-color:#666666;
$green-color:#008020;