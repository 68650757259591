@use 'sass:math';
@function torem($value) {
    @return math.div($value, 16px) * 1rem; 
}

// @function torem($value) {
//     $remValue: ($value / 16) + rem; 
//     @return $remValue;
// }

/* placeholder */
@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

$breakpoints: ('custom-small-mobile-max': (max-width: 359px),
    'portrait-mobile-max': (max-width: 575px),
    'mobile-min': (min-width: 576px),
    'mobile-max': (max-width: 767px),
    'ipad-min': (min-width: 768px),
    'ipad-max': (max-width: 991px), 
    'desktop-min': (min-width: 992px),
    'custom-desktop-max': (max-width: 1024px),
    'desktop-max': (max-width: 1199px),
    'large-desktop-min': (min-width: 1200px),
    'medium-large-desktop-min': (min-width: 1366px),
    'medium-large-desktop-max': (max-width: 1400px),    
);

@mixin breakpoint($name) {
    @if map-has-key($breakpoints, $name) {
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    }

    @else {
        @warn "Couldn't find a breakpoint named `#{$name}`.";
    }
}


// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

// Transition
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition: $what $time $how;
    -ms-transition: $what $time $how;
    -o-transition: $what $time $how;
    transition: $what $time $how;
}

// rgba Border
@mixin border-rgba ($color, $opacity) {
    border: 1px solid rgba(red($color), green($color), blue($color), $opacity);
}

@mixin border-left-rgba ($color, $opacity) {
    border-left: 1px solid rgba(red($color), green($color), blue($color), $opacity);
}

@mixin border-right-rgba ($color, $opacity) {
    border-right: 1px solid rgba(red($color), green($color), blue($color), $opacity);
}

@mixin border-top-rgba ($color, $opacity) {
    border-top: 1px solid rgba(red($color), green($color), blue($color), $opacity);
}

@mixin border-bottom-rgba ($color, $opacity) {
    border-bottom: 1px solid rgba(red($color), green($color), blue($color), $opacity);
}

// Background Color rgba
@mixin background-color-rgba($color, $opacity) {
    background-color: rgba($color, $opacity);
}

// Color rgba
@mixin color-rgba($color, $opacity) {
    color: rgba($color, $opacity);
}

// Horizontal and Center Align using Transform
@mixin center($position) {
    position: absolute;

    @if $position=='vertical' {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    @else if $position=='horizontal' {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translate(-50%);
    }

    @else if $position=='both' {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

// Center Block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


@mixin truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin black-overlay($width: '', $height: '', $opacity: '', ) {
    position: absolute;
    content: "";
    width: $width;
    height: $height;
    @include background-color-rgba($black, $opacity);
}

@mixin custom-container {
    max-width: $container-width;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
}

@mixin hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin p-last-child {
    &:last-child {
        margin-bottom: 0;
    }
}

@mixin btn-font{
    font-weight: 700;
    text-align: center;
    border-radius: 4px;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin common-shadow {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px 0px;
}