.ask-que-modal {
    &__lbl {
        display: block;
        font-weight: 600;
        margin-bottom: 5px;
        font-size: torem(14px);
    }
    .form-floating {
        textarea {
            &.form-control {
                height: 100px;
                resize: none;
            }
        }
    }
}

