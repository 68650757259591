a {
	color: $text-color;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
		color: $primary-color;
	}
}

h2,
.primary-title {
	font-size: torem(28px);

	@include breakpoint(ipad-min) {
		font-size: torem(50px);
	}
}

.secondary-title {
	font-size: torem(20px);

	@include breakpoint(mobile-max) {
		font-size: torem(16px);
	}
}

.para-title {
	font-size: torem(25px);

	@include breakpoint(mobile-max) {
		font-size: torem(22px);
	}
}

.tdp-primary-title {
	font-size: torem(25px);
	font-weight: bold;

	@include breakpoint(ipad-min) {
		font-size: torem(25px);
	}
}

.fw-500 {
	font-weight: 500;
}

.heading-sub-title {
	font-size: torem(14px);
	color: $primary-color;
	font-weight: 400 !important;

	@include breakpoint(mobile-max) {
		font-size: torem(12px);
		color: $primary-color;
		font-weight: 400 !important;
		padding: 0 10px;
	}
}


h1,
h2,
h3,
h4,
h5,
h6 {
	color: $text-color;
	font-family: $primary-fonts;
}

p {
	font-size: torem(14px);
	font-family: $primary-fonts;
	color:$para-color;
}