.btn {
    border-radius: 5px;
    font-weight: 500;

    &-primary {
        background-color: $primary-color;
        border-color: $primary-color;

        &:hover,
        &:focus,
        &:active {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white;
            font-weight: 700;
        }
    }


    &-outline-primary {
        border-color: $primary-color;
        color: $primary-color;

        &:hover,
        &:focus,
        &:active {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white
        }
    }

    &:first-child {
        &:active {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white
        }
    }
}